import { render, staticRenderFns } from "./yaoqing.vue?vue&type=template&id=73edd49c&scoped=true&"
import script from "./yaoqing.vue?vue&type=script&lang=ts&"
export * from "./yaoqing.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73edd49c",
  null
  
)

export default component.exports